<template>
  <div>
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="12">
          <v-card class="card--shadow">
            <v-stepper class="card--shadow" v-model="stepper">
              <v-stepper-header class="card--shadow">
                <v-stepper-step
                  color="purple accent-4"
                  :complete="stepper > 1"
                  step="1"
                >
                  Infection Control checklist
                </v-stepper-step>
               
                <v-divider></v-divider>

                <v-stepper-step color="purple accent-4" step="2">
                  Side effect checklist
                </v-stepper-step>

                <v-divider></v-divider>
              </v-stepper-header>
              
              <v-stepper-items>
                <v-stepper-content step="1">
                  <infection-form-list
                    @nextSideEffect="handleUpdateData"
                    :month_year="month_year"
                    
                  />
                </v-stepper-content>

                <v-stepper-content step="2">
                  <side-effect-form-list
                    @backInfectionList="stepper = 1"
                    @finalSubmit="createHomeVisit"
                    :receivedData="receivedData"
                    :infectionDate="infectionDate"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BackBtn from "@/components/navigation/BackBtn.vue";
import InfectionFormList from "@/components/home-visit/InfectionFormList";
import SideEffectFormList from "@/components/home-visit/SideEffectFormList";
import { mapState } from "vuex";
import { ref } from 'vue';

export default {
  components: {
    BackBtn,
    InfectionFormList,
    SideEffectFormList,
  },
 
  props: {
    sideEffect: Object,
    
  },

  data: () => ({
  
    treatment_startdate:null,
  }),
  setup() {
    const stepper = ref(1);
    const receivedData = ref(null);
    const infectionDate= ref(null);
   
    const handleUpdateData = (data) => {
      
      stepper.value = 2;
      receivedData.value = data.treatment_startdate;
      infectionDate.value=data.infectionDate;
    };
  
    return {
      infectionDate,
      stepper,
      receivedData,
      handleUpdateData,
    };
  },

  computed: {
    ...mapState("HomeVisit", ["homeVisitCollection"]),
    month_year() {
      const month_year = {
        month: this.$route.params.month,
        year: this.$route.params.year,
      };
      return month_year;
    },
  },
  methods: {
    createHomeVisit() {
      this.$store
        .dispatch("HomeVisit/create", this.homeVisitCollection)
        .then(() => {
          this.$router.push(
            `/home-visit/activity/${this.$route.params.month}/${this.$route.params.year}`
          );
        });
    },
  },
  created() {},
};
</script>
